import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import "../style/member-benefit.css";
import $ from "jquery";

const MemberBenefitPage = (props) => {
  useEffect(() => {
    expandData();
  }, []);

  const expandData = () => {
    $(".benefit__mobile label").on("click", function () {
      $(this).toggleClass("benefit__tab--active");
    });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px auto
        }
        .benefit__special .table--benefit {
          display: inline-table
        }
      `,
        }}></style>
      <Layout location={props.location}>
        <SEO
          title="More benefits as you grow your membership status "
          keyword="AYANA member benefits, benefits for member, AYANA rewards"
          decription="Become an AYANA Rewards member and you will receive more benefits as you stay. As you grow your membership level, you will receive more as well. Become a member now and start to earn points!"
        />

        <>
          <div id="contentWrap">
            <div id="index_content" className="admin-setting">
              <div className="benefit__banner">&nbsp;</div>
              <div className="benefit">
                <div className="benefit__banner__mobile mobile--only">
                  <img
                    className="full-width"
                    src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                  />
                </div>
                <div className="benefit__content">
                  <div className="container container--600">
                    <h1 className="text--center">Member Benefits</h1>
                    <p className="text--center">
                      As an AYANA Rewards member, you'll enjoy recognition and
                      luxury across our exclusive destinations. Our three-level
                      membership program accelerates your ascent to elite
                      status, with benefits and perks that grow alongside your
                      spending and visits. Experience rewards as luxurious as
                      you, whether you’re staying overnight or just visiting.
                    </p>
                  </div>
                  <div className="container container--1000">
                    <table className="table table--benefit table--benefit--main">
                      <thead>
                        <tr>
                          <th valign="middle">Program Overview</th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              Silver{" "}
                            </span>
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}> Gold </span>
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              Platinum{" "}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Minimum points/nights required to upgrade membership
                            level{" "}
                          </td>
                          <td>-</td>
                          <td>20 nights or 8,000 points</td>
                          <td>50 nights or 20,000 points</td>
                        </tr>
                        <tr>
                          <td>
                            Minimum points/nights required to retain membership
                            level{" "}
                          </td>
                          <td>-</td>
                          <td>20 nights or 8,000 points</td>
                          <td>50 nights or 20,000 points</td>
                        </tr>
                        <tr>
                          <td>Points Expiry Duration </td>
                          <td colSpan="3">
                            2 years from the last qualifying activities{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>Level Validity or Retention Period </td>
                          <td colSpan="3">
                            Valid until December 31st of the subsequent calendar
                            year following the last upgrade/downgrade/retention{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>Active Status </td>
                          <td colSpan="3">
                            {" "}
                            Members are required to engage in website login
                            activity, a qualifying stay, a qualifying spend, or
                            redeeming AYANA Rewards points within 24 months from
                            the date of their last recorded activity.{" "}
                          </td>
                        </tr>
                        <tr className="table--benefit--subheader">
                          <td colSpan="4">Earn Points Benefits</td>
                        </tr>
                        <tr>
                          <td>Points Earned per IDR 10,000 Spent</td>
                          <td>1 point</td>
                          <td>1 point</td>
                          <td>1 point</td>
                        </tr>
                        <tr>
                          <td>
                            Points Earned per IDR 15,000 Spent (Delonix Only)
                          </td>
                          <td>1 point</td>
                          <td>1 point</td>
                          <td>1 point</td>
                        </tr>
                        <tr>
                          <td>Points Earned on Eligible Stays</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Points Earned on F&#38;B Purchases</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Points Earned on SPA Treatment Purchases</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr className="table--benefit--subheader">
                          <td colSpan="4">Member Benefits</td>
                        </tr>
                        <tr>
                          <td>Member Exclusive Room Discount** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Complimentary 1-tier room upgrade on the same booked
                            room category*{" "}
                          </td>
                          <td>&nbsp;</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Bonus Points on Eligible Spend </td>
                          <td>&nbsp;</td>
                          <td>25%</td>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <td>Early Check-in* </td>
                          <td>&nbsp;</td>
                          <td>11:00</td>
                          <td>11:00</td>
                        </tr>
                        <tr>
                          <td>Late Check-out* </td>
                          <td>&nbsp;</td>
                          <td>16:00</td>
                          <td>18:00</td>
                        </tr>
                        <tr>
                          <td>Complimentary 1-time Breakfast </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>2 person</td>
                        </tr>
                        <tr>
                          <td>Welcome Gift** </td>
                          <td>&nbsp;</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Complimentary amenities during the birthday**{" "}
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Wellness Program Benefit** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            Member Exclusive Benefits<br></br> at Participating
                            Brands***{" "}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Discount at Kawano Group Restaurant
                            (Nationwide){" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>10%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Discount at Liu Li Palace Seafood
                            Restaurant (Jakarta){" "}
                          </td>
                          <td>15%</td>
                          <td>15%</td>
                          <td>15%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Discount at AYANA Bali Restaurants{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Discount at Miami Restaurant at
                            Delonix Hotel Karawang{" "}
                          </td>
                          <td>10%</td>
                          <td>15%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Green Fee Discount at Riverside
                            Golf Club{" "}
                          </td>
                          <td>15%</td>
                          <td>25%</td>
                          <td>40%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Discount at AYANA Spa at AYANA
                            Midplaza Jakarta{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Discount at AYANA Spa at AYANA
                            Komodo Waecicu Beach{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Member Exclusive Benefit at AYANA Spa - AYANA Bali{" "}
                          </td>
                          <td>Add. 15 Mins Massage</td>
                          <td>Add. 15 Mins Massage</td>
                          <td>Add. 30 Mins Massage</td>
                        </tr>
                      </tbody>
                    </table>
                    <small className="mart--lg" style={{ display: "block" }}>
                      * Based on availability upon member’s arrival
                      <br />
                      ** Varies by Property
                      <br />
                      ***
                      <Link className="link" to="/terms-and-conditions-8">
                        <span> Terms and Conditions Applied </span>
                      </Link>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </>
  );
};
export default MemberBenefitPage;
